import { Updater } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Pagination } from 'components/pagination';
import { PageSizeDropdown } from 'components/tables/Table/components/TableFooter/componets';

export type TableFooterProps = {
  tableFilteredDataLength: number;
  colspan?: number;
  pageCount: number;
  currentPage: number;
  showPaginationControls: boolean;
  showPageSizeSelect: boolean;
  setPageIndex: (updater: Updater<number>) => void;
  getCanPreviousPage: () => boolean;
  getCanNextPage: () => boolean;
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  dataLength: number;
  possiblePageSizes?: number[];
};

export const TableFooter = ({
  tableFilteredDataLength,
  pageCount,
  dataLength,
  currentPage,
  pageSize,
  showPageSizeSelect,
  showPaginationControls,
  setPageSize,
  possiblePageSizes = [5, 10, 20],
  colspan,
  ...props
}: TableFooterProps) => {
  const { t } = useTranslation('components');

  const firstItem = useMemo(
    () => (currentPage === 0 ? 1 : (pageSize ?? 0) * currentPage),
    [currentPage, pageSize],
  );

  const lastItem = useMemo(
    () =>
      currentPage === pageCount - 1 ? tableFilteredDataLength : (pageSize ?? 0) * (currentPage + 1),
    [currentPage, pageCount, tableFilteredDataLength, pageSize],
  );

  return (
    <div
      className="flex flex-col md:flex-row bg-brand-gray-light-4 px-4 pt-4 pb-1 justify-between items-center"
      aria-colspan={colspan}
    >
      <div className="flex flex-row text-brand-gray-light-2 items-center gap-2">
        {t('components:tables.Table.footer.itemsPerPageText')}
        <PageSizeDropdown
          pageSize={pageSize}
          setPageSize={setPageSize}
          possiblePageSizes={possiblePageSizes}
        />

        <div className="border-l border-gray-300 h-6 mx-2"></div>

        {/* Info about the number of items in the table and which ones are being shown */}
        {t('components:tables.Table.footer.visibleItemsInfo.showing')}
        <span className="text-brand-gray-light-1">{firstItem}</span>
        {'-'}
        <span className="text-brand-gray-light-1">{lastItem}</span>
        {t('components:tables.Table.footer.visibleItemsInfo.of')}
        <span className="text-brand-gray-light-1">{tableFilteredDataLength}</span>
      </div>

      {showPaginationControls && (
        <Pagination
          pageSize={pageSize}
          pageCount={pageCount}
          dataLength={dataLength}
          currentPage={currentPage}
          {...props}
        />
      )}
    </div>
  );
};

import { Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FormTextareaInput, SubmittingButton } from 'components';
import { ErrorMessageDisplay } from 'components/forms/helpers';

export type ResolveAlertFormValues = {
  resolveReason: string;
};

export const ResolveAlertForm: React.FC<{
  onSubmit: (
    resolveAlertFormValues: ResolveAlertFormValues,
    { resetForm }: { resetForm: () => void },
  ) => Promise<void>;
  existingResolveAlertValues?: ResolveAlertFormValues;
  hideSubmitButton?: boolean;
}> = ({ onSubmit = () => {}, existingResolveAlertValues, hideSubmitButton = false }) => {
  const { t } = useTranslation('components');

  const [, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (
      values: ResolveAlertFormValues,
      { resetForm }: FormikHelpers<ResolveAlertFormValues>,
    ) => {
      setIsSubmitting(true);
      await onSubmit(values, { resetForm });
      setIsSubmitting(false);
    },
    [onSubmit],
  );

  const schema = yup.object({
    resolveReason: yup.string().nullable(),
  });

  const initialValues = schema.cast(existingResolveAlertValues, {
    assert: false,
    stripUnknown: true,
  }) as ResolveAlertFormValues;

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <form id="resolveAlertForm" noValidate onSubmit={handleSubmit}>
          <FormTextareaInput
            label={t('forms.ResolveAlertForm.resolveReason.label')}
            name="resolveReason"
            placeholder={t('forms.ResolveAlertForm.resolveReason.placeholder')}
            required
          />

          {!hideSubmitButton && (
            <SubmittingButton
              buttonText={t('forms.ResolveAlertForm.button.resolve')}
              form="resolveAlertForm"
              submitting={isSubmitting}
              disabled={!values.resolveReason || isSubmitting}
            />
          )}

          <ErrorMessageDisplay />
        </form>
      )}
    </Formik>
  );
};

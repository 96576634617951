import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { TableFooterProps } from 'components/tables/Table/components';

export const PageSizeDropdown = ({
  pageSize,
  setPageSize,
  possiblePageSizes = [],
}: Pick<TableFooterProps, 'pageSize' | 'setPageSize' | 'possiblePageSizes'>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <button
      className="relative flex flex-row items-center text-brand-blue gap-2"
      onClick={() => setIsOpen(!isOpen)}
    >
      {pageSize}
      <FontAwesomeIcon icon={faChevronDown} />
      {isOpen && (
        <div className="absolute bottom-full mb-2 w-16 bg-white border border-gray-200 rounded-md shadow-lg z-50">
          {possiblePageSizes.map(size => (
            <button
              key={size}
              onClick={() => {
                setPageSize && setPageSize(size);
                setIsOpen(false);
              }}
              className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
            >
              {size}
            </button>
          ))}
        </div>
      )}
    </button>
  );
};

import React, { Dispatch, SetStateAction } from 'react';

import { BlueprintPosition } from 'utils/types';

type BlueprintCanvasContextProps = {
  hours: number;
  setHours: Dispatch<SetStateAction<number>>;
  lockSensors: boolean;
  setLockSensors: Dispatch<SetStateAction<boolean>>;
  activeBlueprintPosition?: BlueprintPosition;
  setActiveBlueprintPosition: Dispatch<SetStateAction<BlueprintPosition | undefined>>;
  editModeEnabled: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  hiddenBlueprintPositionIds: string[];
  setHiddenBlueprintPositionsIds: Dispatch<SetStateAction<string[]>>;
  modifyingAttachedSensors: boolean;
  setModifyingAttachedSensors: Dispatch<SetStateAction<boolean>>;
  blueprintId: string;
  blueprintHeight: number | undefined;
  setBlueprintHeight: Dispatch<SetStateAction<number>>;
  editModeTooltip: string;
  setEditModeTooltip: Dispatch<SetStateAction<string>>;
  isFullscreen: boolean;
  setIsFullscreen: Dispatch<SetStateAction<boolean>>;
};

export const BlueprintCanvasContext = React.createContext<BlueprintCanvasContextProps>({
  hours: 0,
  setHours: () => {},
  lockSensors: true,
  setLockSensors: () => {},
  activeBlueprintPosition: undefined,
  setActiveBlueprintPosition: () => {},
  editModeEnabled: false,
  setEditMode: () => {},
  hiddenBlueprintPositionIds: [],
  setHiddenBlueprintPositionsIds: () => {},
  modifyingAttachedSensors: false,
  setModifyingAttachedSensors: () => {},
  blueprintId: '',
  blueprintHeight: undefined,
  setBlueprintHeight: () => {},
  editModeTooltip: '',
  setEditModeTooltip: () => {},
  isFullscreen: false,
  setIsFullscreen: () => {},
});

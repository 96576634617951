import classNames from 'classnames';
import { addHours, differenceInHours, isSameDay, roundToNearestHours, subHours } from 'date-fns';
import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import 'konva/lib/shapes/Image';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Text';
import {
  MutableRefObject,
  DragEvent as ReactDragEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionBar,
  BlueprintCanvasContext,
  BlueprintMoisturePlot,
  MeasurementKey,
  PlayableTimeline,
} from 'components/BlueprintCanvas/components/';
import { RiskScoreLoading } from 'components/BlueprintCanvas/components/RiskScoreLoading';
import { BlueprintSidebar } from 'components/BlueprintCanvas/components/Sidebar/BlueprintSidebar';
import { SensorIconAndLabelConfig } from 'components/BlueprintCanvas/components/types';
import { createWeatherIconFromEvent } from 'components/BlueprintCanvas/components/WeatherIcon';
import CanvasStage, {
  CanvasStageContext,
  DEFAULT_STAGE_STATE,
  ImageSize,
  StageState,
} from 'components/CanvasStage';
import { PIN_CONFIG } from 'components/CanvasStage/components/PinConfig';
import LoadingCard from 'components/cards/LoadingCard';
import BlueprintSensorModal from 'components/modals/BlueprintSensorModal';
import SensorSelectModal from 'components/modals/SensorSelectModal';
import { brandOrangeLight1, getSensorStateColor } from 'utils/colors';
import { BlueprintViewStateContext } from 'utils/contexts';
import { sensorId2NameFunc } from 'utils/formatting';
import { useImage, useTimePeriod, useWindowSize } from 'utils/hooks';
import {
  useBlueprint,
  useBlueprintEvents,
  useBlueprintPositions,
  useBlueprintSensorRiskScoreValues,
  useBlueprintSensors,
  useBlueprintSensorValues,
  useSensorGroup,
} from 'utils/hooks/data';
import { truncateNumber } from 'utils/numbers';
import { convertRiskScoreToRiskIndex } from 'utils/risk-index';
import { signalStrengthToState } from 'utils/sensor/state';
import { getSignalStrengthStateText } from 'utils/sensor/texts';
import { getSensorTransmission } from 'utils/sensor/transmissions';
import { BlueprintPosition, BlueprintViewStateType } from 'utils/types';

const FALLBACK_CANVAS_HEIGHT_PX = 500;

export const Canvas: React.FC<{
  blueprintId: string;
  stageHeightPercentage?: number; // Used to calculate height based on viewport height
  onlyEnableSensorIds?: string[];
  onlyShowSensorIds?: string[];
  onlyShowTooltipforSensorIds?: string[];
  enableToolbox?: boolean;
  enableWheelScrolling?: boolean;
  enableDragging?: boolean;
}> = ({
  blueprintId,
  onlyEnableSensorIds,
  onlyShowTooltipforSensorIds,
  onlyShowSensorIds,
  stageHeightPercentage = 1,
  enableToolbox = false,
  enableWheelScrolling = false,
  enableDragging = false,
}) => {
  const { t } = useTranslation('components');

  // Contexts
  const {
    hours,
    setHours,
    activeBlueprintPosition,
    setActiveBlueprintPosition,
    editModeEnabled,
    setEditMode,
    hiddenBlueprintPositionIds,
    blueprintHeight,
    setBlueprintHeight,
    setEditModeTooltip,
    isFullscreen,
  } = useContext(BlueprintCanvasContext);
  const { blueprintViewState } = useContext(BlueprintViewStateContext);
  // States
  const [blueprintImageSize, setBlueprintImageSize] = useState<ImageSize>({ width: 0, height: 0 });
  const [sensorSelectModalState, setSensorSelectModalState] = useState<{
    show: boolean;
    x?: number;
    y?: number;
  }>({ show: false });
  const [modalBlueprintPositionState, setModalBlueprintPositionState] = useState<{
    blueprintPosition?: BlueprintPosition;
    enabled?: boolean;
    show: boolean;
  }>({ show: false });
  const [draggingPosition, setDraggingPosition] = useState<BlueprintPosition | null>(null);
  const mainStageRef = useRef<Konva.Stage>() as MutableRefObject<Konva.Stage> | undefined;
  const [stageState, setStageState] = useState<StageState>(DEFAULT_STAGE_STATE);

  // Canvas size
  const [windowHeight] = useWindowSize();
  useEffect(() => {
    setBlueprintHeight(windowHeight * stageHeightPercentage || FALLBACK_CANVAS_HEIGHT_PX);
  }, [windowHeight, stageHeightPercentage, setBlueprintHeight, isFullscreen]);

  const canvasDifRef = useRef<HTMLDivElement>(null);

  // Pop-up moisture plot
  const [plotOffset, setPlotOffset] = useState({ x: 0, y: 0 });
  const [moisturePlotEnabled, setMoisturePlotEnabled] = useState(true);
  const [showMoisturePlot, setShowMoisturePlot] = useState(false);
  const [moisturePlotActiveKonvaElement, setMoisturePlotActiveKonvaElement] = useState<
    Shape | undefined
  >(undefined); // This is used to propagate the onclick event from the moisture plot to the sensor icon
  const onMoisturePlotHide = () => {
    setShowMoisturePlot(false);
  };

  // Tooltips are enabled if any of the three options are enabled
  const highlightCertainSensors = !!onlyEnableSensorIds && onlyEnableSensorIds.length > 0;

  // Refs to store references to the Label and Image components for highlighted sensors
  const highlightedImagesRefs = useRef<(Konva.Image | null)[]>([]);

  // Hooks

  const { blueprint, isPending: isPendingBlueprint } = useBlueprint(blueprintId);
  const {
    sensors,
    removeSensorFromBlueprintById,
    isPending: isPendingSensors,
  } = useBlueprintSensors(blueprintId);

  const {
    blueprintPositions,
    updateSensorPositionById,
    isPending: isPendingPositions,
  } = useBlueprintPositions(blueprintId);

  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();
  const max = differenceInHours(timeTo, timeFrom);

  const { events: weatherEvents } = useBlueprintEvents(blueprintId, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
  });

  const { sensorId2Transmission: sensorId2TransmissionWithoutRiskScore } = useBlueprintSensorValues(
    blueprintId,
    {
      timeFrom: subHours(timeFrom, 12), // ensuring there is transmisisons from the timelines beginning
      timeTo,
    },
  );
  const {
    sensorId2Transmission: sensorId2TransmissionWithRiskScore,
    isError: failedToGetRiskScore,
  } = useBlueprintSensorRiskScoreValues(
    blueprintId,
    {
      timeFrom: subHours(timeFrom, 12), // ensuring there is transmisisons from the timelines beginning
      timeTo,
    },
    { enableGet: blueprintViewState === BlueprintViewStateType.RiskScore },
  );

  const sensorId2Transmission =
    blueprintViewState === BlueprintViewStateType.RiskScore
      ? sensorId2TransmissionWithRiskScore
      : sensorId2TransmissionWithoutRiskScore;

  const { sensorGroup: projectGroup } = useSensorGroup(blueprint?.project_group_id);

  // Images
  const [blueprintImage] = useImage(blueprint?.storage_url);

  // Mappings
  const sensorId2Name = sensorId2NameFunc(sensors);
  const positionIdToPosition = Object.fromEntries(
    (blueprintPositions || []).map(position => [position.id, position]),
  );
  const isSensorIdEnabled = Array.isArray(onlyEnableSensorIds)
    ? Object.fromEntries(
        (sensors || []).map(sensor => [sensor.id, onlyEnableSensorIds.includes(sensor.id)]),
      )
    : Object.fromEntries((sensors || []).map(sensor => [sensor.id, true]));

  // Handle sensor dropped on div
  const onDrop = async (
    {
      xFraction,
      yFraction,
    }: {
      xFraction: number;
      yFraction: number;
    },
    e: ReactDragEvent<HTMLDivElement>,
  ) => {
    const blueprintSensorId = e.dataTransfer!.getData('blueprint-sensor-id');

    if (blueprintSensorId) {
      await updateSensorPositionById({
        sensorId: blueprintSensorId,
        position_x: xFraction,
        position_y: yFraction,
      });
    }
  };

  const handleOnBlueprintClick = ({ evt, target }: Konva.KonvaEventObject<MouseEvent>) => {
    evt.preventDefault();
    if (!editModeEnabled) return;

    const stage = target.getStage();
    if (!stage) return;

    const scale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;

    setSensorSelectModalState({
      show: true,
      x: pointerPosition.x / scale - stage.x() / scale,
      y: pointerPosition.y / scale - stage.y() / scale,
    });
  };

  const tooltipText = (sensorId?: string) => {
    if (!sensorId2Transmission || !sensorId) return;

    const transmission = getSensorTransmission(
      timeTo,
      max,
      hours,
      sensorId2Transmission,
      sensorId,
      blueprintViewState,
    );
    const name = sensorId2Name[sensorId];

    const tooltipLines: string[] = [];
    tooltipLines.push(`${name}`);
    const { moisture, spreading_factor, risk_score } = transmission;
    if (blueprintViewState === BlueprintViewStateType.Moisture) {
      tooltipLines.push(`WMC: ${moisture ? `${moisture.toFixed(1)}%` : 'n/a'}`);
    } else if (blueprintViewState === BlueprintViewStateType.SignalStrength) {
      tooltipLines.push(
        `${spreading_factor ? `${getSignalStrengthStateText(signalStrengthToState(transmission.spreading_factor))}` : 'n/a'}`,
      );
    } else if (blueprintViewState === BlueprintViewStateType.RiskScore) {
      const riskIndex = convertRiskScoreToRiskIndex(risk_score);
      tooltipLines.push(
        `${typeof riskIndex === 'number' ? `Risk: ${riskIndex.toFixed(1)}` : 'n/a'}`,
      );
    }

    return tooltipLines.join('\n');
  };

  // Determine what images to show
  const blueprintPositionsToShow = onlyShowSensorIds
    ? (blueprintPositions || []).filter(blueprintPosition =>
        onlyShowSensorIds.includes(blueprintPosition.sensor_id),
      )
    : blueprintPositions || [];

  // Show the blueprint moisture plot for the given sensor when
  // We hover over the sensor pin icon
  // If we're in edit mode we also change the cursor to grab to indicate the sensor can be moved
  const handleOnMouseEnterSensorPin = ({ target: pinIcon }: Konva.KonvaEventObject<MouseEvent>) => {
    const parentKonvaGroup = pinIcon.parent!;
    const blueprintPosition = positionIdToPosition[parentKonvaGroup.attrs.blueprintPositionId];
    if (blueprintPosition) setActiveBlueprintPosition(blueprintPosition);

    // Get offset for the moisture plot
    const mainStage = mainStageRef!.current!;
    const stageContainer = mainStage.container();
    const stageBounding = stageContainer.getBoundingClientRect();
    const targetAbsolutePosition = pinIcon.absolutePosition();

    let pinWidth = PIN_CONFIG.baseWidth;
    let pinHeight = PIN_CONFIG.baseHeight;
    if (pinIcon.attrs.isActive) {
      pinWidth = pinWidth * PIN_CONFIG.sensorPinActiveScale;
      pinHeight = pinHeight * PIN_CONFIG.sensorPinActiveScale;
    }

    // When the sensor is active on the blueprint the pin icon x,y origin changes as it scales,
    // so our offset to the center of the pin needs to change because we want the plot to be in the
    // same place in both cases
    const xOffsetRatio = { active: 0.15, inactive: 0.3 };
    const yOffsetRatio = { active: 0.1, inactive: 0.35 };
    const xOffsetToPinCenter =
      pinWidth * (pinIcon.attrs.isActive ? xOffsetRatio.active : xOffsetRatio.inactive);
    const yOffsetToPinCenter =
      pinHeight * (pinIcon.attrs.isActive ? yOffsetRatio.active : yOffsetRatio.inactive);

    // Plot offset === absolute dom position of the top left corner of the pin icon when scaled
    //   = MainStage position in dom + pin icon position in stage - an offset to the center(ish) of the pin icon
    setPlotOffset({
      x: stageBounding!.x + targetAbsolutePosition.x - xOffsetToPinCenter,
      y: stageBounding!.y + targetAbsolutePosition.y - yOffsetToPinCenter,
    });

    // Activate the moisture plot if we aren't in edit mode
    if (!editModeEnabled) {
      setMoisturePlotActiveKonvaElement(pinIcon as Shape);
      setShowMoisturePlot(true);
    } else {
      // Show we can move the sensor in edit mode with a grab cursor
      stageContainer!.style.cursor =
        editModeEnabled && parentKonvaGroup.attrs.isEnabled ? 'grab' : 'pointer';
      setEditModeTooltip(t('blueprints.Sidebar.tooltips.repositionSensor'));
    }
  };

  // Reset the cursor when the mouse leaves the sensor pin icon and label konva group
  // If we're in edit mode we also change the tooltip and unset the active blueprint position
  const handleOnMouseLeaveImageAndLabel = ({ target }: Konva.KonvaEventObject<MouseEvent>) => {
    const container = target!.getStage()!.container();
    container.style.cursor = 'default';
    if (editModeEnabled) {
      setActiveBlueprintPosition(undefined);
      setEditModeTooltip(t('blueprints.Sidebar.tooltips.positionSensors'));
    }
  };

  // Callback on on drag end
  const handleOnDragEndImage = async ({ target }: Konva.KonvaEventObject<DragEvent>) => {
    const x = truncateNumber(
      target.attrs.x + target.attrs.pinWidth / 2,
      0,
      blueprintImageSize.width,
    );
    const y = truncateNumber(target.attrs.y + target.attrs.pinHeight, 0, blueprintImageSize.height);
    // Compute fractions for backend
    const xFraction = x / blueprintImageSize.width;
    const yFraction = y / blueprintImageSize.height;
    const position = positionIdToPosition[target.attrs.blueprintPositionId];
    setDraggingPosition(null);
    setEditModeTooltip(t('blueprints.Sidebar.tooltips.positionSensors'));

    await updateSensorPositionById({
      sensorId: position.sensor_id,
      position_x: xFraction,
      position_y: yFraction,
    });
  };

  // Callback on drag start
  const handleOnDragStartImage = ({ target }: Konva.KonvaEventObject<DragEvent>) => {
    const position = positionIdToPosition[target.attrs.blueprintPositionId];
    setEditModeTooltip(t('blueprints.Sidebar.tooltips.dropSensor'));
    setDraggingPosition(position);
  };

  // Callback on click
  const handleOnClickImageAndLabel = ({ target }: Konva.KonvaEventObject<DragEvent>) => {
    if (editModeEnabled) return;

    const position = positionIdToPosition[target.parent?.attrs.blueprintPositionId];
    const enabled = target.parent?.attrs.isEnabled;

    setModalBlueprintPositionState({
      blueprintPosition: position,
      show: true,
      enabled,
    });
  };

  //Create sensor icons + label configs
  const sensorIconAndLabelConfigs: SensorIconAndLabelConfig[] = blueprintPositionsToShow
    .filter(blueprintPosition => blueprintPosition.isPositionDefined)
    .map(blueprintPosition => {
      // Boolean config
      const isActive = activeBlueprintPosition?.id === blueprintPosition.id;
      const isEnabled = isSensorIdEnabled[blueprintPosition.sensor_id];
      const isHighlighted = highlightCertainSensors && isEnabled;
      const isVisible = !hiddenBlueprintPositionIds.includes(blueprintPosition.id);
      const isDraggable = isEnabled && editModeEnabled;
      const showTooltip = onlyShowTooltipforSensorIds
        ? onlyShowTooltipforSensorIds.includes(blueprintPosition.sensor_id)
        : blueprintPosition.id === draggingPosition?.id
          ? false
          : true;

      // Content config
      const color = getSensorStateColor(
        timeTo,
        max,
        hours,
        sensorId2Transmission,
        blueprintPosition.sensor_id,
        blueprintViewState,
      );
      const labelText = `${tooltipText(blueprintPosition.sensor_id)}`;
      //Position
      const xPos = blueprintPosition.position_x || 0;
      const yPos = blueprintPosition.position_y || 0;
      return {
        positionId: blueprintPosition.id,
        isActive,
        isEnabled,
        isVisible,
        isHighlighted,
        isDraggable,
        showTooltip,
        xPos,
        yPos,
        labelText,
        color,
        onDragEnd: handleOnDragEndImage,
        onDragStart: handleOnDragStartImage,
        onMouseEnterPin: handleOnMouseEnterSensorPin,
        onMouseLeave: handleOnMouseLeaveImageAndLabel,
        onClick: handleOnClickImageAndLabel,
      };
    });

  useEffect(() => {
    setHours(differenceInHours(roundToNearestHours(timeTo), roundToNearestHours(timeFrom)));
  }, [timeFrom, timeTo, setHours]);

  // Move highlighted sensors on top
  if (highlightCertainSensors) {
    highlightedImagesRefs.current.forEach(ref => ref?.moveToTop());
    highlightedImagesRefs.current.forEach(ref => ref?.moveToTop());
  }

  // Define sensors that are attached to the blueprint, but not placed
  const nonPlacedBlueprintIds = blueprintPositions
    ?.filter(x => !x.isPositionDefined)
    .map(x => x.sensor_id);
  const nonPlacedSensors = (sensors || [])
    .filter(sensor => nonPlacedBlueprintIds?.includes(sensor.id))
    .filter(sensor => isSensorIdEnabled[sensor.id]);

  const isPending = isPendingBlueprint || isPendingSensors || isPendingPositions;

  const currentWeatherEvent = useMemo(() => {
    const currentTime = addHours(timeFrom, hours);
    const weatherEventAtCurrentTime = (weatherEvents || []).filter(weatherEvent =>
      isSameDay(weatherEvent.timestamp, currentTime),
    );
    if (weatherEventAtCurrentTime.length > 0) {
      return weatherEventAtCurrentTime[0];
    } else {
      return undefined;
    }
  }, [hours, timeFrom, weatherEvents]);

  if (isPending) return <LoadingCard count={4} />;

  const onPanCanvas = ({ evt, target }: Konva.KonvaEventObject<DragEvent>) => {
    // There are some cases where the user is able to pan when the moisture pop up plot is active,
    // this handles it by keeping the x and y pos up to date as we're panning
    if (editModeEnabled || !showMoisturePlot) return;
    const xPos = target!.getStage()!.getPointerPosition()!.x;
    const yPos = target!.getStage()!.getPointerPosition()!.y;
    setPlotOffset({ x: xPos, y: yPos });
  };

  return (
    <div
      className="flex flex-col relative h-full bg-green-300"
      ref={canvasDifRef}
      style={!isFullscreen ? { maxHeight: blueprintHeight } : {}}
    >
      <ActionBar
        blueprintId={blueprintId}
        blueprintTitle={blueprint?.descriptionTruncated || ''}
        blueprintGroup={projectGroup?.name || ''}
        blueprintGroupId={projectGroup?.id || ''}
        blueprintTags={blueprint?.tags || ''}
        editModeEnabled={editModeEnabled}
        setEditMode={setEditMode}
      />

      {/* Canvas */}
      <div className="relative flex w-full grow overflow-hidden">
        <div className="relative w-5/6 box-border">
          <CanvasStageContext.Provider value={{ stageState, setStageState, mainStageRef }}>
            <CanvasStage
              stageHeight={blueprintHeight}
              imageSize={blueprintImageSize}
              setImageSize={setBlueprintImageSize}
              backgroundImage={blueprintImage}
              onClick={handleOnBlueprintClick}
              onDrop={onDrop}
              enableWheelScrolling={enableWheelScrolling}
              enableDragging={enableDragging}
              enableToolbox={enableToolbox}
              onDragEnd={onPanCanvas}
              sensorIconAndLabelConfigs={sensorIconAndLabelConfigs}
            />
          </CanvasStageContext.Provider>
          <div className="flex flex-col absolute bottom-0 w-full left-0">
            <MeasurementKey className="mx-6" />
            <div
              className={classNames(
                'transition-[height] duration-700 overflow-hidden',
                !editModeEnabled ? 'h-20' : 'h-0',
              )}
            >
              <PlayableTimeline
                show={!editModeEnabled}
                playOnMount={false}
                loop={false}
                weatherEvents={weatherEvents}
              />
            </div>
          </div>
          <div className="absolute top-0 left-0 m-4">
            {currentWeatherEvent &&
              createWeatherIconFromEvent(currentWeatherEvent, 'text-4xl', 'bottom', false)}
          </div>
        </div>
        {editModeEnabled && (
          // Construction border for edit mode
          <div
            className="absolute h-full w-5/6 pointer-events-none"
            style={{
              border: '5px solid rgba(0, 0, 0, 0)',
              borderImage: `repeating-linear-gradient(-55deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, ${brandOrangeLight1} 10px, ${brandOrangeLight1} 20px) 5`,
            }}
          />
        )}
        {/** Risk score loading screen */}
        {blueprintViewState === BlueprintViewStateType.RiskScore &&
          Object.keys(sensorId2TransmissionWithRiskScore || {}).length === 0 && (
            <RiskScoreLoading failedToGetRiskScore={failedToGetRiskScore} />
          )}
        <BlueprintSidebar
          sensorsAttachedToBlueprint={sensors}
          positions={blueprintPositions}
          moisturePlotEnabled={moisturePlotEnabled}
          setMoisturePlotEnabled={setMoisturePlotEnabled}
        />
      </div>
      {/* Plots */}
      {moisturePlotEnabled && (
        <BlueprintMoisturePlot
          show={showMoisturePlot}
          offset={plotOffset}
          timeFrom={timeFrom}
          timeTo={timeTo}
          onHide={onMoisturePlotHide}
          konvaElement={moisturePlotActiveKonvaElement}
          sensorIdsToNameMap={sensorId2Name}
        />
      )}
      {/* Modals */}
      <BlueprintSensorModal
        blueprintPosition={modalBlueprintPositionState.blueprintPosition}
        show={modalBlueprintPositionState.show}
        showActions={modalBlueprintPositionState.enabled}
        setShow={show => {
          if (!show) {
            setModalBlueprintPositionState({ show: false });
          }
        }}
        onDetach={async blueprintPosition => {
          await removeSensorFromBlueprintById(blueprintPosition.sensor_id);
          setModalBlueprintPositionState({ show: false });
        }}
        onResetPosition={async blueprintPosition => {
          await updateSensorPositionById({
            sensorId: blueprintPosition.sensor_id,
            position_x: null,
            position_y: null,
          });
          setModalBlueprintPositionState({ show: false });
        }}
      />
      <SensorSelectModal
        show={sensorSelectModalState.show}
        setShow={show =>
          setSensorSelectModalState({
            ...sensorSelectModalState,
            show,
          })
        }
        onSelect={async sensor => {
          if (sensor && sensorSelectModalState.x && sensorSelectModalState.y) {
            const xFraction = sensorSelectModalState.x / blueprintImageSize.width;
            const yFraction = sensorSelectModalState.y / blueprintImageSize.height;

            await updateSensorPositionById({
              sensorId: sensor.id,
              position_x: xFraction,
              position_y: yFraction,
            });

            setSensorSelectModalState({
              show: false,
            });
          }
        }}
        sensors={nonPlacedSensors}
        infoText={t('blueprints.BlueprintCanvas.SensorSelectModal.infoText')}
      />
    </div>
  );
};

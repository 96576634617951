import { faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import queryString from 'query-string';
import { MouseEvent, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas//components/BlueprintCanvasContext';
import { RiskScoreExplanation } from 'components/BlueprintCanvas/components/BlueprintMoisturePlot/components';
import MoistureAndPrecipPlot from 'components/plots/CombinedPlots/MoistureAndPrecipPlot';
import { LoadingPlot } from 'components/plots/insights_plots';
import {
  useSensorReferenceValues,
  useSensorTransmissions,
  useSensorWeatherObservations,
  useSensorWeightedEMC,
} from 'utils/hooks/data';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import PlotSession from 'utils/sessions/PlotSession';
import { WeatherPrecipitationDataTuple, WeatherWindDataTuple } from 'utils/types/PlotTypes';

const CONTAINER_WIDTH_PX = 640;
const CONTAINER_HEIGHT_PX = 400;
const PADDING_SIZE_PX = 80;

export interface BlueprintMoisturePlotProps {
  show: boolean;
  offset: { x: number; y: number };
  timeFrom: Date;
  timeTo: Date;
  konvaElement: Shape<ShapeConfig> | undefined;
  onHide: () => void;
  sensorIdsToNameMap: { [k: string]: string };
}

export const BlueprintMoisturePlot: React.FC<BlueprintMoisturePlotProps> = ({
  show,
  offset,
  timeFrom,
  timeTo,
  konvaElement,
  onHide,
  sensorIdsToNameMap,
}) => {
  const { t } = useTranslation('components');
  const { blueprintId, activeBlueprintPosition, setActiveBlueprintPosition } =
    useContext(BlueprintCanvasContext);

  const sensorId = activeBlueprintPosition?.sensor_id || '';
  const sensorName = sensorIdsToNameMap[sensorId];
  // Get transmission data
  const { transmissions, isPending: isPendingTransmissions } = useSensorTransmissions(sensorId, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
    includeMetaColumns: false,
  });
  const { weightedEMCTuples, isPending: isPendingWeightedEMC } = useSensorWeightedEMC(sensorId, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
  });
  const { referenceValuesTuples, isPending: isPendingReferenceValues } = useSensorReferenceValues(
    sensorId,
    {
      fromTimestamp: timeFrom,
      toTimestamp: timeTo,
    },
  );

  const isPending = isPendingTransmissions || isPendingWeightedEMC || isPendingReferenceValues;

  // Get weather data
  const { sensorWeatherObservations } = useSensorWeatherObservations(sensorId, {
    fromTimestamp: timeFrom,
    toTimestamp: timeTo,
    includeHumidity: true,
    includePrecip: true,
    includeTemperature: true,
    includeWind: true,
  });
  const weatherDataPrecipitation = useMemo(
    () =>
      sensorWeatherObservations?.map(({ timestamp, rain_1h }) => [
        timestamp,
        rain_1h,
      ]) as WeatherPrecipitationDataTuple[],
    [sensorWeatherObservations],
  );
  const weatherDataWind = useMemo(
    () =>
      sensorWeatherObservations?.map(({ timestamp, wind_speed, wind_dir }) => [
        timestamp,
        wind_speed,
        wind_dir,
      ]) as WeatherWindDataTuple[],
    [sensorWeatherObservations],
  );

  const closePlot = () => {
    onHide();
    setActiveBlueprintPosition(undefined);
  };

  const [windowWidth, windowHeight] = useWindowSize();

  // Handle plot position:
  //  If the cursor is above the canvas center, show the plot below the cursor
  //    Else show above
  //  If the cursor is to the left of the canvas center, show the plot to the right of the cursor
  //    Else show to the left
  const positionRightOfScreenCenter = offset.x < windowWidth * 0.5;
  const positionAboveScreenCenter = offset.y > windowHeight * 0.5;
  const xOffset =
    offset.x + PADDING_SIZE_PX * 0.5 + (positionRightOfScreenCenter ? 0 : -CONTAINER_WIDTH_PX);
  const yOffset =
    offset.y + PADDING_SIZE_PX * 0.5 + (positionAboveScreenCenter ? -CONTAINER_HEIGHT_PX : 0);
  // Handle click events
  const handleMouseClick = () => {
    // Because we're using a div on top of the canvas as padding around the icon, we need to propagate click events to the canvas
    if (!konvaElement) return;
    konvaElement.fire('dragstart');
    konvaElement.fire('click');
  };

  const handleMouseClickGraph = (e: MouseEvent<HTMLDivElement>) => {
    // We don't however want to propagate a click on the plot to the canvas, since it would open the sensor modal on top of the plot
    e.stopPropagation();
  };

  const linkToSensorPage = {
    pathname: `/user/sensors/${sensorId}`,
    search: queryString.stringify({
      timeFrom: timeFrom?.toISOString(),
      timeTo: timeTo?.toISOString(),
    }),
  };

  const paddingSize = show ? PADDING_SIZE_PX : 0;
  return (
    // The outer div here gives some padding around the sensor icon, so the user has a larger area to hover over and can go from the sensor icon
    // to the plot without the plot disappearing, while keeping the label of the sensor icon visible.
    <div
      id="blueprint-moisture-plot"
      className="fixed z-40"
      style={{ top: offset.y, left: offset.x, width: paddingSize, height: paddingSize }}
      onMouseLeave={closePlot}
      onClick={handleMouseClick}
    >
      {show &&
        createPortal(
          <div
            className="fixed bg-white rounded-md border-2 border-brand-gray-light-1 z-[51] p-4"
            style={{
              top: yOffset,
              left: xOffset,
              height: CONTAINER_HEIGHT_PX,
              width: CONTAINER_WIDTH_PX,
            }}
            onClick={handleMouseClickGraph}
          >
            <div className="pb-2">
              <Link
                target="_blank"
                rel="noreferrer noopener"
                to={linkToSensorPage}
              >{`${t('blueprints.CanvasMoisturePlot.title')} ${sensorName}`}</Link>
            </div>
            {isPending && <LoadingPlot height="h-80" backgroundColor="bg-white" />}
            {!isPending && (
              <PlotSession timePeriod={[timeFrom, timeTo]}>
                <MoistureAndPrecipPlot
                  emc={weightedEMCTuples}
                  referenceValues={referenceValuesTuples}
                  transmissions={transmissions}
                  transmissionForecasts={[]} // forecasts seem to be a bit wonky for some reason so ignoring for now
                  weatherDataPrecip={weatherDataPrecipitation}
                  weatherDataWind={weatherDataWind}
                  showToolbar={false}
                  showAnimation={false}
                  enableZoom={false}
                  fixMoistureYAxis={true}
                  rotateXAxisLabelsForLargeDateRange={true}
                  sensorId={sensorId}
                  sensorName={sensorName}
                />
                <div className="flex justify-between">
                  <RiskScoreExplanation
                    blueprintId={blueprintId}
                    selectedSensorId={sensorId}
                    timeFrom={timeFrom}
                    timeTo={timeTo}
                  />
                  <Link
                    className="flex items-center text-xs text-brand-gray-light-2 hover:text-brand-gray"
                    target="_blank"
                    rel="noreferrer noopener"
                    to={linkToSensorPage}
                  >
                    {t('blueprints.CanvasMoisturePlot.openSensorPageInNewTab')}
                    <FontAwesomeIcon className="mx-1" icon={faArrowRight} size="sm" />
                  </Link>
                </div>
              </PlotSession>
            )}
            <div
              className="absolute top-2 right-4 text-brand-gray hover:text-brand-gray-light-1 hover:cursor-pointer"
              onClick={closePlot}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};

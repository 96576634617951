import classNames from 'classnames';
import { HTMLAttributes, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext, Canvas } from 'components/BlueprintCanvas/components';
import { BlueprintPosition } from 'utils/types';

export const BlueprintCanvas: React.FC<
  {
    blueprintId: string;
    stageHeight?: number;
    onlyEnableSensorIds?: string[];
    onlyShowSensorIds?: string[];
    onlyShowTooltipforSensorIds?: string[];
    showSensors?: boolean;
    showHeader?: boolean;
    showPlayableTimeline?: boolean;
    enableWheelScrolling?: boolean;
    enableDragging?: boolean;
    showToolbox?: boolean;
    playTimelapseOnMount?: boolean;
    loopTimelapse?: boolean;
    editModeEnabled?: boolean;
    setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  blueprintId,
  stageHeight,
  onlyEnableSensorIds,
  onlyShowSensorIds,
  onlyShowTooltipforSensorIds,
  showHeader = false,
  showSensors = false,
  showPlayableTimeline = false,
  enableWheelScrolling = false,
  enableDragging = false,
  showToolbox = false,
  playTimelapseOnMount = false,
  loopTimelapse = false,
  className = 'shadow',
  editModeEnabled = false,
  setEditMode = () => {},
  ...props
}) => {
  const { t } = useTranslation('components');
  // States
  const [hours, setHours] = useState(0);
  const [lockSensors, setLockSensors] = useState(true);
  const [activeBlueprintPosition, setActiveBlueprintPosition] = useState<BlueprintPosition>();
  const [hiddenBlueprintPositionIds, setHiddenBlueprintPositionsIds] = useState<string[]>([]);
  const [modifyingAttachedSensors, setModifyingAttachedSensors] = useState(false);
  const [blueprintHeight, setBlueprintHeight] = useState(0); // This gets calculated in the Canvas component
  const [editModeTooltip, setEditModeTooltip] = useState(
    t('blueprints.Sidebar.tooltips.positionSensors'),
  );
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    isFullscreen ? handle.enter() : handle.exit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);

  return (
    <FullScreen handle={handle}>
      <BlueprintCanvasContext.Provider
        value={{
          hours,
          setHours,
          lockSensors,
          setLockSensors,
          activeBlueprintPosition,
          setActiveBlueprintPosition,
          editModeEnabled,
          setEditMode,
          hiddenBlueprintPositionIds,
          setHiddenBlueprintPositionsIds,
          modifyingAttachedSensors,
          setModifyingAttachedSensors,
          blueprintId,
          blueprintHeight,
          setBlueprintHeight,
          editModeTooltip,
          setEditModeTooltip,
          isFullscreen,
          setIsFullscreen,
        }}
      >
        <div
          className={classNames(className, {
            'h-svh w-full': isFullscreen,
            'h-[85vh]': !isFullscreen,
          })}
          {...props}
        >
          <Canvas
            blueprintId={blueprintId}
            stageHeightPercentage={stageHeight}
            onlyEnableSensorIds={onlyEnableSensorIds}
            onlyShowSensorIds={onlyShowSensorIds}
            onlyShowTooltipforSensorIds={onlyShowTooltipforSensorIds}
            enableWheelScrolling={enableWheelScrolling}
            enableDragging={enableDragging}
            enableToolbox={showToolbox}
          />
        </div>
      </BlueprintCanvasContext.Provider>
    </FullScreen>
  );
};

import { faArrowRight, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { PlacesType, Tooltip } from 'react-tooltip';

type InfoTooltipProps = {
  id: string;
  message: string;
  place?: PlacesType;
  link?: string;
  linkText?: string;
  iconClassName?: string;
};

export const InfoTooltip = ({
  id,
  message,
  link,
  linkText,
  place = 'bottom',
  iconClassName,
}: InfoTooltipProps) => {
  const { t } = useTranslation('components');

  const [show, setShow] = useState(false);

  return (
    <div onMouseEnter={() => setShow(true)}>
      <FontAwesomeIcon
        id={id}
        icon={faQuestionCircle}
        className={classNames(
          iconClassName,
          'cursor-pointer text-brand-blue outline-none bg-white rounded-full [mask-image:radial-gradient(circle,white_100%,transparent_100%)]',
        )}
      />

      {show &&
        createPortal(
          <Tooltip
            anchorSelect={`#${id}`}
            place={place}
            clickable
            className="tooltip w-[280px] max-w-[280px] p-4 z-50 rounded-2xl shadow-xl bg-gradient-to-b from-brand-blue to-brand-blue-light-1 opacity-95"
          >
            {/* Close icon */}
            <div className="absolute top-1 right-2 cursor-pointer m-1 text-sm">
              <FontAwesomeIcon icon={faTimes} onClick={() => setShow(false)} />
            </div>

            <div className="flex flex-col text-center">
              {/* Message */}
              <span className="text-sm font-medium p-1 m-1">{message}</span>

              {/* "Learn More" link */}
              {link && (
                <a
                  href={link}
                  className="flex items-center justify-end text-brand-lime group text-xs"
                  target="_blank" // Open link in new tab
                  rel="noreferrer"
                >
                  <span className="mr-2 group-hover:translate-x-1 transition-transform duration-300">
                    {linkText ?? t('InfoTooltip.readMoreLinkText')}
                  </span>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="group-hover:translate-x-1 transition-transform duration-300"
                  />
                </a>
              )}
            </div>
          </Tooltip>,
          document.body,
        )}
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import Transmission, { parseTransmission } from 'utils/types/Transmission';

export const useBlueprintSensorRiskScoreValues = (
  blueprintId: string,
  { timeFrom, timeTo }: { timeFrom?: Date; timeTo?: Date },
  { enableGet }: HookOptions = {},
) => {
  const queryKey = [
    'useBlueprintSensorRiskScoreValues',
    blueprintId,
    timeFrom?.getTime(),
    timeTo?.getTime(),
  ];

  // Sensors - Get
  const {
    data: sensorId2Transmission,
    isPending,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/blueprints/${blueprintId}/sensor_riskscore_values`, {
        params: { from_timestamp: timeFrom, to_timestamp: timeTo },
      }),
    select: (sensor_id2transmissions: any) =>
      Object.entries(sensor_id2transmissions).reduce(
        (
          o: { [key: string]: Transmission[] | undefined },
          [sensorId, transmissions]: [string, any],
        ) => ({
          ...o,
          [sensorId]: transmissions.map(parseTransmission),
        }),
        {},
      ),
    enabled: enableGet && !!blueprintId,
  });

  return {
    sensorId2Transmission,
    isPending,
    isError,
  };
};

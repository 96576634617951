import classNames from 'classnames';
import { sub } from 'date-fns/sub';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleSelect from 'components/forms/SimpleSelect';
import { sm } from 'utils/breakpoints';
import { useWindowSize } from 'utils/hooks';

export type PredefinedRange = {
  name: string;
  value: Date;
};

export const PredefinedRanges = ({
  selectedPredefinedRange,
  setSelectedPredefinedRange,
  onChange,
  daysUpToToday,
  setDaysUpToToday,
  setupCompletedDate,
  entireHistoryDate,
  latestActiveDate,
}: {
  selectedPredefinedRange: PredefinedRange | undefined;
  setSelectedPredefinedRange: (range: PredefinedRange) => void;
  onChange: (dates: [Date | null, Date | null]) => void;
  daysUpToToday: string;
  setDaysUpToToday: (days: string) => void;
  setupCompletedDate?: Date;
  entireHistoryDate?: Date;
  latestActiveDate?: Date;
}) => {
  const { t } = useTranslation('components');

  const [windowWidth] = useWindowSize();
  const isMobileView = windowWidth < sm;

  const [days, setDays] = useState(daysUpToToday);

  const now = new Date();

  const daysUpToTodayOption = {
    name: t('inputs.DatePickerInput.PredefinedRanges.daysUpToToday'),
    value: sub(now, { days: Number(days) }),
  };

  let options: PredefinedRange[] = [
    {
      name: t('inputs.DatePickerInput.PredefinedRanges.pastWeek'),
      value: sub(now, { days: 7 }),
    },
    {
      name: t('inputs.DatePickerInput.PredefinedRanges.past3Months'),
      value: sub(now, { months: 3 }),
    },
    {
      name: t('inputs.DatePickerInput.PredefinedRanges.past6Months'),
      value: sub(now, { months: 6 }),
    },
    {
      name: t('inputs.DatePickerInput.PredefinedRanges.pastYear'),
      value: sub(now, { years: 1 }),
    },
    {
      name: t('inputs.DatePickerInput.PredefinedRanges.past2Years'),
      value: sub(now, { years: 2 }),
    },
  ];

  if (setupCompletedDate) {
    options.push({
      name: t('inputs.DatePickerInput.PredefinedRanges.setupCompleted'),
      value: setupCompletedDate,
    });
  }

  if (latestActiveDate) {
    options.push({
      name: t('inputs.DatePickerInput.PredefinedRanges.latestActiveDate'),
      value: sub(latestActiveDate, { weeks: 1 }),
    });
  }

  if (entireHistoryDate) {
    // Remove options that's from before 'entiry history' date
    options.push({
      name: t('inputs.DatePickerInput.PredefinedRanges.entireHistory'),
      value: entireHistoryDate,
    });
    options = options.filter(x => x.value >= entireHistoryDate);
  }

  return (
    <>
      {!isMobileView && (
        <div className="flex flex-col border-r border-brand-gray-light-4 w-full overflow-hidden">
          {options?.map((option, index) => (
            <button
              key={index}
              className={classNames(
                'border-b border-brand-gray-light-4 p-2 text-left hover:bg-brand-lime',
                { 'bg-brand-lime': selectedPredefinedRange?.name === option.name },
              )}
              onClick={() => {
                setSelectedPredefinedRange(option);
                onChange([option.value, now]);
              }}
            >
              <span className="truncate text-brand-gray-light-1 text-sm">{option.name}</span>
            </button>
          ))}
          <div
            className={classNames(
              'flex flex-row items-center p-2 gap-2 justify-start hover:bg-brand-lime',
              {
                'bg-brand-lime': selectedPredefinedRange?.name === daysUpToTodayOption.name,
              },
            )}
          >
            <input
              className={'flex w-10 appearance-none text-xs items-center text-center'}
              type="number"
              min="0"
              onChange={e => setDays(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setDaysUpToToday(days);
                  setSelectedPredefinedRange(daysUpToTodayOption);
                  onChange([daysUpToTodayOption.value, now]);
                }
              }}
              value={days}
              placeholder="0"
            />
            <span className="text-brand-gray-light-1 text-sm">
              {t('inputs.DatePickerInput.PredefinedRanges.daysUpToToday')}
            </span>
          </div>
        </div>
      )}
      {isMobileView && (
        <SimpleSelect
          className="mb-1"
          onSelect={value => onChange([value, now])}
          placeholder={t('inputs.DatePickerInput.PredefinedRanges.select')}
          options={options}
        />
      )}
    </>
  );
};

import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SensorTagsForm from 'components/forms/SensorTagsForm';
import { InfoTooltip } from 'components/InfoTooltip';
import Modal from 'components/modals/Modal';
import SelectSensorFromTagsModal from 'components/modals/SelectSensorFromTagsModal';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useCustomerSensors } from 'utils/hooks/data';
import SensorTags from 'utils/types/SensorTags';

const SensorTagsModal: React.FC<
  React.PropsWithChildren<{
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    onSubmit: (sensorTags: SensorTags) => Promise<void>;
    sensorTags?: SensorTags;
    sensorId?: string;
    showCopyFromSensorButton?: boolean;
  }>
> = ({
  show,
  setShow,
  onSubmit,
  sensorTags,
  sensorId,
  showCopyFromSensorButton = false,
  children,
}) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const { isAdmin } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { sensors } = useCustomerSensors(customerId, {
    includeTags: true,
    enableGet: showCopyFromSensorButton,
  });

  // Do not include current sensor in sensors to copy from
  const sensorsFiltered = useMemo(
    () => sensors?.filter(sensor => sensor.id !== sensorId),
    [sensors, sensorId],
  );

  return (
    <Modal
      title={
        <div className="flex flex-row items-end gap-1">
          <span>{t('components:modals.SensorTagsModal.title')}</span>
          <InfoTooltip
            id="info-tooltip-sensor-tags-modal"
            message={t('components:modals.SensorTagsModal.InfoTooltip.text')}
            link="https://help.tector.com/features-and-functionalities-on-the-sensor-values-page"
            linkText={t('components:modals.SensorTagsModal.InfoTooltip.linkText')}
          />
        </div>
      }
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      {showCopyFromSensorButton && (
        <button className="float-right underline" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon className="mr-1" icon={faFileImport} />
          <span>{t('components:modals.SensorTagsModal.copyFromSensor')}</span>
        </button>
      )}

      <SensorTagsForm
        sensorTags={sensorTags}
        onSubmit={onSubmit}
        sensorIdForPredictions={sensorId}
        showBadges
        showCasingTag
        showEnvironmentTags
        showPrimaryPlacementTags
        showRoomAndOrientationTags
        showTheSensorTags
        showTitles
        showUsageTag={isAdmin}
      />

      {showCopyFromSensorButton && (
        <SelectSensorFromTagsModal
          show={showModal}
          setShow={setShowModal}
          onSelect={async sensor => {
            onSubmit(sensor.tags);
          }}
          sensors={sensorsFiltered}
        />
      )}

      {children}
    </Modal>
  );
};

export default SensorTagsModal;

import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseTransmission } from 'utils/types/Transmission';

export const useSensorLatestTransmission = (
  sensorId: string,

  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['useSensorLatestTransmission', sensorId];

  const {
    data: latestTransmission,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/latest_transmission`),
    select: (transmissionRaw?: any) =>
      transmissionRaw ? parseTransmission(transmissionRaw) : undefined,
    enabled: !!sensorId && enableGet,
  });

  return {
    latestTransmission,
    isPending: isPending || isLoading || isFetching,
  };
};
